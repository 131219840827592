import React, { useState, useEffect } from 'react';
import PaypalCheckout from './PaypalCheckout';
import { Link } from 'react-router-dom';

const Cartdisplay = ({ cart, setCart }) => {
    const [subtotal, setSubtotal] = useState(0)
    const [shipping, setShipping] = useState(0)
    const [total, setTotal] = useState(0)

    function removeItem(product) {
        setCart(cart.filter(item => item.id != product.id))
    }

    useEffect(() => {
        let sub = 0
        let ship = 0
        cart.forEach((item) =>  {
            sub += ((item.salePrice) ? item.salePrice : item.originalPrice) * item.quantity
            ship += (7.97 * item.quantity)
        })
        setShipping(ship)
        setSubtotal(sub)
        setTotal(sub + ship)

    }, [cart])

    function changeQuantity(product, quantity) {
        setCart(cart.map(item => {
            if (item.id == product.id) {
                return {
                    ...item, 
                    quantity: quantity
                }
            }
            else {
                return item
            }
        }))
    }

    return (
        <>
            <div className='cart__container'>
                <h1 className='cart--title'>Cart</h1>
                <div className='cart__subtitle-row'>
                    <p className='cart__sub--item'>Item</p>
                    <p className='cart__sub--quantity'>Quantity</p>
                    <p className='cart__sub--price'>Price</p>
                </div>
                <div className='cart__body'>
                    {(!cart || cart.length == 0) && 
                        (<><p className='cart--empty'>Your cart is currently empty.</p>
                        <Link className='cart--return' to='/products'>Return to shop</Link></>)}

                        {cart.map(item => {
                            return (
                                <div className='cart__item--row' key={item.id}>
                                    <div className='cart__item--wrapper'>
                                        <img className='cart__item--img' src={item.url} alt="" />
                                        <div className='cart__item--info'>
                                            <h4 className='cart__item--title'>{item.title}</h4>
                                            <p className='cart__item--sub-price'>{'£' + ((item.salePrice) ? item.salePrice : item.originalPrice).toFixed(2)}</p>
                                            <button className='cart__item--remove' onClick={() => removeItem(item)}>Remove</button>
                                        </div>
                                    </div>
                                    <div className='counter__wrapper'>
                                        <input 
                                            className='cart__item--counter'
                                            type="number" 
                                            min={0} 
                                            max={99}
                                            value={item.quantity}
                                            onChange={(event) => changeQuantity(item, event.target.value)}  
                                            />
                                    </div>
                                    <div className='cart__item--price'>
                                        {'£' + (((item.salePrice) ? item.salePrice : item.originalPrice) * item.quantity).toFixed(2)}
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                </div>
                {(cart && cart.length > 0) && (
                    <div className='cart__total'>
                        <div className='cart__total--subtotal'>
                            <p>Subtotal</p>
                            <p>{'£' + subtotal.toFixed(2)}</p>
                        </div>
                        <div className='cart__total--shipping'>
                            <p>Shipping</p>
                            <p>{'£' + shipping.toFixed(2)}</p>
                        </div>
                        <div className='cart__total--total'>
                            <p>Total</p>
                            <p>{'£' + total.toFixed(2)}</p>
                        </div>
                        <div className='checkout__container'>
                            <PaypalCheckout 
                                className='checkout--button'
                                cart={cart} 
                                total={total} 
                                subtotal={subtotal}
                                shipping={shipping}
                                />
                        </div>
                    </div>)}
            </div>
        </>
    );
}

export default Cartdisplay;
