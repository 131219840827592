import React from 'react';

const Productheader = () => {
    return (
        <main id='products'>
            <h5 className='product-header--sub'>
                WELCOME TO
            </h5>
            <h1 className='product-header--title'>
                BROWSE OUR PRODUCTS
            </h1>
        </main>
    );
}

export default Productheader;

