import React from 'react';
import { Link } from 'react-router-dom';
import HeaderIMG from '../images/main image.jpeg'

const Header = () => {
    return (
        <header id='header'>
            <div className='header__container'>
                <h2 className='header--sub'>Sunshine Lifestyle</h2>
                <h1 className='header--title'>GET YOURSELF READY</h1>
                <h1 className='header--title'>FOR <span className='orange'>SUMMER</span></h1>
                <Link to="/products" className='header--button'>BROWSE BARBEQUES</Link>
            </div>
            <img src={HeaderIMG} className="header-img" />
        </header>
        
    );
}

export default Header;
