// Product list

export const products = [
    {
        id: 1,
        popularity: 17,
        category: 'Spare Part',
        title: 'Ignition - Ignition Box 3B Small',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/03/a3igbx02s.gif",
        originalPrice: 8.90,
        salePrice: null,
    },
    {
        id: 2,
        popularity: 2,
        category: 'Spare Part',
        title: 'Ignition - Ignition Box 3B Large',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/03/a3igbx02s.gif",
        // url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/03/a3igbx01s.gif",
        originalPrice: 9.90,
        salePrice: null,
    },
    {
        id: 3,
        popularity: 18,
        category: 'Accessory',
        title: 'Rotisserie',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/05/rot01-300x300.gif",
        originalPrice: 59.90,
        salePrice: 58.00,
    },
    {
        id: 4,
        popularity: 19,
        category: 'Spare Part',
        title: 'Regulator Propane Screw On',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/05/gasregscrbuta-300x300.gif",
        originalPrice: 9.90,
        salePrice: null,
    },
    {
        id: 5,
        popularity: 20,
        category: 'Spare Part',
        title: 'Regulator B&P Screw On',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/05/gasregscrprop-300x300.gif",
        originalPrice: 9.90,
        salePrice: null,
    },
    {
        id: 6,
        popularity: 15,
        category: 'Spare Part',
        title: 'Regulator Butan Clip-on',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/05/gasregclipbuta-300x300.gif",
        originalPrice: 9.90,
        salePrice: null,
    },
    {
        id: 7,
        popularity: 21,
        category: 'Spare Part',
        title: 'Regulator Propane Clip-on',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/05/gasregclipprop-300x300.gif",
        originalPrice: 9.90,
        salePrice: null,
    },
    {
        id: 8,
        popularity: 6,
        category: 'Spare Part',
        title: 'Sunshine Gas Connector',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/05/hoco01l.gif",
        originalPrice: 17.90,
        salePrice: null,
    },
    {
        id: 9,
        popularity: 10,
        category: 'Spare Part',
        title: 'Ignition - Piezo Push Button 3B',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/05/a3pzsw01s.gif",
        originalPrice: 9.90,
        salePrice: null,
    },
    // {
    //     id: 10,
    //     category: 'Spare Part',
    //     title: 'Piezo Push Button - Heritage 2',
    //     url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/05/a2pzsw01s.gif",
    //     originalPrice: 9.90,
    //     salePrice: null,
    // },
    {
        id: 10,
        popularity: 3,
        category: 'Spare Part',
        title: 'Burner Clip',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/05/burnerclip-300x300.gif",
        originalPrice: 1.99,
        salePrice: null,
    },
    {
        id: 11,
        popularity: 16,
        category: 'Accessory',
        title: 'Gas Bottle Cover',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/05/gasbottlecover-300x300-1.gif",
        originalPrice: 19.99,
        salePrice: null,
    },
    {
        id: 12,
        popularity: 22,
        category: 'Spare Part',
        title: 'Ignition Box - 2 Burner',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/05/ignitionbox2b-300x300.gif",
        originalPrice: 8.99,
        salePrice: null,
    },
    {
        id: 13,
        popularity: 11,
        category: 'Spare Part',
        title: 'Burners - Set of Four',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/04/sp2-4burner-300x300.gif",
        originalPrice: 116.00,
        salePrice: 99.00,
    },
    {
        id: 14,
        popularity: 23,
        category: 'Accessory',
        title: 'Vinyl Cover 3-Burner Roasting Hood',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/04/Vinyl-Cover-3-Burner-RHood-300x300.gif",
        originalPrice: 44.90,
        salePrice: null,
    },
    {
        id: 15,
        popularity: 7,
        category: 'Accessory',
        title: 'Vinyl Cover 4-Burner Roasting Hood',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/04/Vinyl-Cover-4-Burner-RHood-300x300.gif",
        originalPrice: 49.90,
        salePrice: null,
    },
    {
        id: 16,
        popularity: 12,
        category: 'Accessory',
        title: 'Vinyl Cover 5-Burner Roasting Hood',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/04/Vinyl-Cover-5-Burner-RHood-300x300.gif",
        originalPrice: 54.90,
        salePrice: null,
    },
    {
        id: 17,
        popularity: 4,
        category: 'Accessory',
        title: 'Vinyl Cover 3-Burner Flatbed',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/04/Vinyl-Cover-3-Burner-Flatbed-300x300.gif",
        originalPrice: 44.90,
        salePrice: null,
    },
    {
        id: 18,
        popularity: 13,
        category: 'Accessory',
        title: 'Vinyl Cover 4-Burner Flatbed',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/04/Vinyl-Cover-4-Burner-Flatbed-300x300.gif",
        originalPrice: 49.90,
        salePrice: null,
    },
    {
        id: 19,
        popularity: 24,
        category: 'Barbecue',
        title: 'Sunshine Burwood Roaster',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/03/Burwood4000-300x300.gif",
        originalPrice: 399.00,
        salePrice: null,
    },
    {
        id: 20,
        popularity: 8,
        category: 'Spare Part',
        title: 'Ceramic Briquettes (20 pieces)',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/03/ceramic-briquette-300x300.gif",
        originalPrice: 29.98,
        salePrice: 28.00,
    },
    {
        id: 21,
        popularity: 5,
        category: 'Spare Part',
        title: 'Gas Valve',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/03/barkingdish-300x300-2.gif",
        originalPrice: 19.90,
        salePrice: null,
    },
    {
        id: 22,
        popularity: 14,
        category: 'Accessory',
        title: 'Vinyl Cover 5-Burner Flatbed',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/04/Vinyl-Cover-5-Burner-Flatbed-300x300.gif",
        originalPrice: 54.90,
        salePrice: null,
    },
    {
        id: 23,
        popularity: 1,
        category: 'Spare Part',
        title: 'Lavarock Tray 3B/4B/5B',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/03/LavaRock03-300x300.gif",
        originalPrice: 39.90,
        salePrice: 38.00,
    },
    {
        id: 24,
        popularity: 9,
        category: 'Spare Part',
        title: 'Burner - Fits all Sunshine BBQ',
        url: "https://sunshinebbq.co.uk/wp-content/uploads/2019/03/burner-300x300-1.gif",
        originalPrice: 29.90,
        salePrice: 28.00,
    }
]