import React from 'react';
import Cartdisplay from '../Components/CartDisplay';
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer';

const Cart = ({ cart, setCart }) => {
    return (
        <>
            <main id='cart'>
                <div className='cart__link-bar--container'>
                    <div className='cart__link-bar'>
                        <Link className='cart__link-bar--home' to='/'>Home</Link>
                        <p>{'>'}</p>
                        <Link className='cart__link-bar--cart' to='/cart'>Cart</Link>
                    </div>
                </div>
                <Cartdisplay 
                    cart={cart}
                    setCart={setCart}
                />
            </main>
            <Footer />
        </>
    );
}

export default Cart;
