import React, { useEffect, useState } from 'react';
import Logo from '../images/sunshine logo.gif';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBasketShopping } from '@fortawesome/free-solid-svg-icons';

const Nav = ({ cart }) => {

    const [counter, setCounter] = useState(0)
    
    useEffect(() => {
        setCounter(itemCount())
    },[cart])

    function itemCount() {
        console.log(cart)
        let counter = 0
        if (cart) {
            cart.forEach((item) => {
                counter += +item.quantity
            })
        } 
        return counter
    }

    return (
        <nav id='nav'>
            <div className='nav__container'>
                <figure className='logo--wrapper'>
                    <Link  to="/">
                        <img className="nav--logo" src={Logo}  />
                    </Link>
                </figure>
                <ul className='nav-list'>
                    <li className='nav-list--item'><Link to="/">HOME</Link></li>
                    <li className='nav-list--item'><Link to="/products">PRODUCTS</Link></li>
                    <li className='nav-list--item nav--cart'><Link to="/cart">CART</Link></li>
                    {/* <li className='nav-list--item'><Link to="/">CHECKOUT</Link></li> */}
                    <li className='nav-list--icon'>
                        <Link to="/cart">
                            <FontAwesomeIcon 
                                icon={faBasketShopping} 
                                className="nav-list--basket"
                            />
                        </Link>
                        {(counter > 0) && <span className='basket--count'>{counter}</span>}
                    </li>
                </ul>
                
            </div>
        </nav>
    );
}

export default Nav;
