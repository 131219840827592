import React, {useState, useEffect} from 'react';
import { products } from '../products.js'

const Display = ({ addToCart }) => {
    const [display, setDisplay] = useState(products)
    const [count, setCount] = useState(24)

    function changeCategory(category) {
        if (category =="DEFAULT") {
            setDisplay(products)
            setCount(24)
        }
        else if (category == "ACCESSORY") {
            setDisplay(products.filter((product) => product.category === "Accessory"))
            setCount(8)
        }
        else if (category == "BARBECUE") {
            setDisplay(products.filter((product) => product.category === "Barbecue"))
            setCount(1)
        }
        else if (category == "SPARE PART") {
            setDisplay(products.filter((product) => product.category === "Spare Part"))
            setCount(15)
        }
    }

    function changeSort(sort) {
        if (sort == "LATEST") {
            setDisplay(display.slice().sort((a, b) => (a.id - b.id)))
        }
        else if (sort == "POPULARITY") {
            setDisplay(display.slice().sort((a, b) => (a.popularity - b.popularity)))
        }
        else if (sort == "PRICE, LOW TO HIGH") {
            setDisplay(display.slice().sort((a, b) => (a.originalPrice - b.originalPrice)))
        }
        else if (sort == "PRICE, HIGH TO LOW") {
            setDisplay(display.slice().sort((a, b) => (b.originalPrice - a.originalPrice)))
        }
    }

    return (
        <section id='display'>
            <div className='display__container'>
                <div className='display__bar'>
                    <h5>SHOWING 1-{count} OF {count} RESULTS</h5>
                    <div className='display__bar--categories'>
                        <h5 className='display__bar--title'>CATEGORIES:</h5>
                        <select 
                            defaultValue="DEFAULT"
                            onChange={(event) => changeCategory(event.target.value)}
                            >
                            <option value="DEFAULT">All</option>
                            <option value="ACCESSORY">Accessory</option>
                            <option value="BARBECUE">Barbecue</option>
                            <option value="SPARE PART">Spare Part</option>
                        </select>
                    </div>
                    <div className='display__bar--sort'>
                        <h5 className='display__bar--title'>SORT BY:</h5>
                        <select 
                            defaultValue="LATEST"
                            onChange={(event) => changeSort(event.target.value)}
                            >
                            <option value="LATEST">Latest</option>
                            <option value="POPULARITY">Popularity</option>
                            <option value="PRICE, LOW TO HIGH">Price, Low to High</option>
                            <option value="PRICE, HIGH TO LOW">Price, High to Low</option>
                        </select>
                     </div>
                </div>
            </div>
            <div className='display-products__container'>
                {display.map((product) => 
                    <div className='display-products--product' key={product.id}>
                        <img className='product--img' src={product.url}/>
                        <h4 className='product--title'>{product.title}</h4>
                        <p className='product--category'>{product.category}</p>
                        <div className='product__prices'>
                            {product.salePrice ? 
                                <><h5 className='product__prices--grey'>{'£' + product.originalPrice.toFixed(2)}</h5> 
                                <h5 className='product__prices--original'>{'£' + product.salePrice.toFixed(2)}</h5></>
                            : 
                                <h5 className='product__prices--original'>{'£' + product.originalPrice.toFixed(2)}</h5>}
                        </div>
                        <button className="product--button" onClick={() => addToCart(product)}>Add to cart</button>
                    </div>
                )}    
            </div>
        </section>
    );
}

export default Display;
