import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faClock } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
    return (
        <footer id='footer'>
            <div className='footer__container'>
                <div className='footer__phone'>
                    <FontAwesomeIcon 
                        icon={faPhone} 
                        className="footer--icon"
                        />
                    <p> 07710512668</p>
                </div>
                <div className='footer__email'>
                    <FontAwesomeIcon 
                        icon={faEnvelope} 
                        className="footer--icon"
                        />
                    <p> sunshinebarbecue@gmail.com</p>
                </div>
                <div className='footer__clock'>
                    <FontAwesomeIcon
                        icon={faClock} 
                        className="footer--icon"
                        />
                    <p> Mon - Fri 9:00am - 6:00pm</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
