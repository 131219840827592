import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Productheader from '../Components/ProductHeader';
import Display from '../Components/Display';
import Footer from '../Components/Footer';

const Shop = ({ cart, setCart }) => {


    function addToCart(product) {
        const dupeItem = cart.find(item => item.id == product.id)
        if (dupeItem) {
            setCart(cart.map(item => {
                if (item.id == dupeItem.id) {
                    return {
                        ...item, quantity: item.quantity + 1
                    }
                }
                else {
                    return item
                }
            }))
        }
        else {
            setCart([...cart, {...product, quantity: 1}])
        }  
    }

    return (
        <>
            <div className='shop__link-bar--container'>
                <div className='shop__link-bar'>
                    <Link className='shop__link-bar--home' to='/'>Home</Link>
                    <p>{'>'}</p>
                    <Link className='shop__link-bar--products' to='/products'>Products</Link>
                </div>
            </div>
            <Productheader/>
            <Display 
                addToCart={addToCart} 
            />
            <Footer />
        </>
    );
}

export default Shop;
