import './index';
import Home from './Pages/Home';
import Shop from './Pages/Shop';
import Cart from './Pages/Cart'
import Nav from './Components/Nav';
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {

  const [cart, setCart] = useState([])

  return (
      <Router>
          <Nav cart={cart}/>
        <Routes>
          <Route path="/"
            element={<Home />}
          />
          <Route path="/products"
            element={<Shop 
              cart={cart} 
              setCart ={setCart}
              />}
          />
          <Route path="/cart"
            element={<Cart
              cart={cart}
              setCart ={setCart}
            />}
          />
        </Routes>
      </Router>
  );
}

export default App;
