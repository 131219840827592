import React,  { useRef, useEffect, useState } from 'react';

const PaypalCheckout = ({ cart, total, subtotal, shipping }) => {



    const paypal = useRef()
    const isInitialMount = useRef(true)
    const items_array = cart.map((item => {
        return {
                name: item.title,
                unit_amount: {
                    currency_code: "GBP",
                    value: (item.salePrice) ? item.salePrice : item.originalPrice
                },
                quantity: item.quantity
            }
    }))

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } 
        else {
            window.paypal.Buttons({
                createOrder: (data, actions, err) => {
                    return actions.order.create({
                        intent: "CAPTURE",
                        purchase_units: [
                            {
                                amount: {
                                    currency_code: "GBP",
                                    value: total,
                                    breakdown: {
                                        item_total: {
                                            currency_code: "GBP",
                                            value: subtotal.toFixed(2)
                                        },
                                        shipping: {
                                            currency_code: "GBP",
                                            value: shipping.toFixed(2)
                                        }
                                    }
                                },
                                items: items_array
                            },
                            
                            
                        ],
                        
                    })
                },
                onApprove: async (data, actions) => {
                    const order = await actions.order.capture();
                    console.log(order)
                },
                onError: (err) => {
                    console.log(err)
                },
                style: {
                    label:   'pay'
                }
            }).render(paypal.current)
        }
    }, [isInitialMount.current]);
    return (
        <div className='checkout' ref={paypal}></div>
    );
}

export default PaypalCheckout;
