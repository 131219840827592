import React from 'react';
import Header from '../Components/Header';
import FooterHome from '../Components/FooterHome';
import Footer from '../Components/Footer';

const Home = () => {
    return (
        <section id='home'>
            <Header />
            <FooterHome />
        </section>
    );
}

export default Home;
